import queryString from 'query-string'

export function getAuthCode() {
	const values = queryString.parse(window.location.search)
	return values.code || values.auth
}

export function getAccessToken() {
	const values = queryString.parse(window.location.hash)
	return values.access_token
}

export function getEmailProvider() {
	const values = queryString.parse(window.location.search)
	return values.email_provider
}

export function getRedirectUri() {
	const values = queryString.parse(window.location.search)
	return values.redirect_uri
}

export function getQueryParams() {
	return queryString.parse(window.location.search)
}
