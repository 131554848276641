let client, auth, redirect_uri, scope, options

exports.useProxy = false

exports.getAuthorizationUri = (oauthConfig) => {
	client = oauthConfig.client
	auth = oauthConfig.auth
	redirect_uri = oauthConfig.redirect_uri
	scope = oauthConfig.scope.join(' ')
	options = oauthConfig.options
	prompt = oauthConfig.prompt

	let authorizationUri = getOauthClient().authorizationCode.authorizeURL({redirect_uri, scope, access_type: 'offline', prompt})
	console.log('authorizationUri: ', authorizationUri)
	return authorizationUri
}

let getOauthClient = () => {
	let oauth = require('simple-oauth2')
	let config = {
		client,
		auth,
		options
	}

	return oauth.create(config)
}
