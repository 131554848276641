const config = require('../config.json')

const oauthConfig = {
	client: {
		id: config.azure_client_id
	},
	auth: {
		authorizeHost: 'https://login.microsoftonline.com',
		authorizePath: 'common/oauth2/v2.0/authorize',
		tokenHost: 'https://login.microsoftonline.com',
		tokenPath: 'common/oauth2/v2.0/token'
	},
	redirect_uri: config.redirect_uri,
	scope: [
		'openid',
		'profile',
		'email',
		'offline_access',
		'User.Read',
		'Mail.Read',
		// 'Mail.Send'
	],
	prompt: 'consent'
}

if (config.SWITCH_OAUTH_USE_SEND_SCOPE === true) {
	oauthConfig.scope.push('Mail.Send')
}

module.exports = oauthConfig
