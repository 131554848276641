import React from 'react'
import {getAccessToken, getEmailProvider, getQueryParams, getRedirectUri} from '../../helpers'

const appConfig = require('../../config.json')

const oauth = require('../../helpers/oauth')

const azureConfig = require('../../helpers/azure-oauth-config')
const googleConfig = require('../../helpers/gmail-oauth-config')
const defaultConfig = require('../../helpers/default-oauth-config')

class InitialPage extends React.Component {
	componentDidMount() {
		const emailProvider = getEmailProvider()
		const redirectUri = getRedirectUri()
		let queryParams = getQueryParams()

		sessionStorage.setItem('token', getAccessToken())
		sessionStorage.setItem('emailProvider', emailProvider)
		sessionStorage.setItem('redirectUri', getRedirectUri())
		sessionStorage.setItem('version', queryParams.version)
		sessionStorage.setItem('skipScan', queryParams.skipScan)
		if (!appConfig.allowedRedirects.some(r => redirectUri.includes(r))) {
			console.log('invalid redirect URI was provided')
			return
		}
		let config = defaultConfig
		if (emailProvider === 'gmail') {
			config = googleConfig
			let redirectAuthUrl = oauth.getAuthorizationUri(config)
			window.location = redirectAuthUrl
		} else if (emailProvider === 'outlook') {
			config = azureConfig
			let redirectAuthUrl = oauth.getAuthorizationUri(config)
			window.location = redirectAuthUrl
		} else {
			console.log('invalid email provider was provided')
		}
	}

	render() {
		return (
			<div className="content">
				<div className="loader"></div>
			</div>
		)
	}
}

export default InitialPage
