const config = require('../config.json')

const oauthConfig = {
	client: {
		id: config.google_client_id
	},
	auth: {
		authorizeHost: 'https://accounts.google.com',
		authorizePath: 'o/oauth2/v2/auth',
		tokenHost: 'https://www.googleapis.com',
		tokenPath: 'oauth2/v4/token'
	},
	redirect_uri: config.redirect_uri,
	scope: [
		'openid',
		'https://www.googleapis.com/auth/userinfo.profile',
		'https://www.googleapis.com/auth/userinfo.email',
		'https://www.googleapis.com/auth/gmail.readonly',
		// 'https://www.googleapis.com/auth/gmail.send'
	],
	prompt: 'consent'
}

if (config.SWITCH_OAUTH_USE_SEND_SCOPE === true) {
	oauthConfig.scope.push('https://www.googleapis.com/auth/gmail.send')
}

module.exports = oauthConfig
