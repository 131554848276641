import React from 'react'
import {Route, Switch} from 'react-router-dom'
import InitialPage from './components/initial/InitialPage'
import CallBackPage from './components/after/CallBackPage'

export default function () {
	return (
		<Switch>
			<Route path="/" exact component={CallBackPage}/>
			<Route path="/init" exact component={InitialPage}/>
		</Switch>
	)
}
