module.exports = {
	client: {
		id: '',
		secret: ''
	},
	auth: {
		authorizeHost: '',
		authorizePath: '',
		tokenHost: '',
		tokenPath: ''
	},
	redirect_uri: 'http://localhost:3000',
	scope: []
}
