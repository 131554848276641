export function authHeader() {
	let token = sessionStorage.getItem('token')
	console.log('retrieving token', token)
	const headers = {'Content-Type': 'application/json'}
	if (token) {
		headers.Authorization = 'Bearer ' + token
	}

	return headers
}
