import React, {Component} from 'react'
import {authHeader, getAuthCode} from '../../helpers'
import {CREATE_EMAIL_ACCOUNT_URL, CREATE_EMAIL_ACCOUNT_URL_V2, GMAIL_PROVIDER} from '../../helpers/Constants'

import './CallBackPage.css'

const azureConfig = require('../../helpers/azure-oauth-config')
const googleConfig = require('../../helpers/gmail-oauth-config')
const defaultConfig = require('../../helpers/default-oauth-config')

class CallBackPage extends Component {
    componentDidMount() {
        let emailProvider = sessionStorage.getItem('emailProvider')
        let redirectUri = sessionStorage.getItem('redirectUri')
        let version = sessionStorage.getItem('version')
        let skipScan = sessionStorage.getItem('skipScan')

        const code = getAuthCode()
        console.log('authCode', code)
        if (!code) {
            window.location = redirectUri + '/not-enrolled'
            throw new Error('unable to retrieve auth code')
        }

        console.log('retrieved emailProvider', emailProvider)
        let oauthRedirectUri = defaultConfig.redirect_uri
        if (emailProvider === 'gmail') {
            oauthRedirectUri = googleConfig.redirect_uri
        } else if (emailProvider === 'outlook') {
            oauthRedirectUri = azureConfig.redirect_uri
        }

        let postBody
        let createEmailAccountURL
        if (parseInt(version) === 2) {
            createEmailAccountURL = CREATE_EMAIL_ACCOUNT_URL_V2
            postBody = {
                authorization_code: code,
                email_provider: emailProvider || GMAIL_PROVIDER,
                redirect_uri: oauthRedirectUri,
                ...((skipScan === false || skipScan === 'false') ? {skip_scan: false} : {}),
            }

        } else {
            createEmailAccountURL = CREATE_EMAIL_ACCOUNT_URL
            postBody = {
                authorizationCode: code,
                emailProvider: emailProvider || GMAIL_PROVIDER,
                redirectUri: oauthRedirectUri
            }
        }

        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(postBody)
        }
        console.log('calling createEmailAccount', JSON.stringify(requestOptions))
        fetch(createEmailAccountURL, requestOptions)
            .then(response => {
                if (response.status === 200 || response.status === 201 || response.status === 202) {
                    window.location = redirectUri + '/enrolled'
                } else if (response.status === 403) {
                    window.location = redirectUri + '/duplicate-email-error'
                } else {
                    window.location = redirectUri + '/not-enrolled'
                    throw new Error('api response error')
                }
            }).catch(e => {
            this.props.history.push('/?error=true')
        })
    }

    render() {
        return (
            <div className="content">
                <div className="loader"></div>
            </div>
        )
    }
}

export default CallBackPage
